import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Grid2 } from '@mui/material';
import { changePasswordUserV2 } from '../../../Helpers/ApplicationHelpers';
import { useEffect } from 'react';
const ChangePasswordModal = ({ token, open, handleClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setSuccessMessage('');
    setErrorMessage('');
  }, [open]);

  const handleSubmit = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    if (!currentPassword || !newPassword || !confirmPassword) {
      setErrorMessage('All fields are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirmation do not match.');
      return;
    }

    if (newPassword.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      return;
    }


    const result = await changePasswordUserV2(token, currentPassword, newPassword);

    if (result.success) {
      setSuccessMessage('Password changed successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        handleClose();
      }, 1500);
    } else {
      setErrorMessage(result.message || 'Failed to change the password.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="change-password-title"
      aria-describedby="change-password-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}
      >
        <Typography id="change-password-title" variant="h6" component="h2">
          Change Password
        </Typography>
        <Grid2 container spacing={2} direction="column" sx={{ mt: 2 }}>
          {errorMessage && (
            <Grid2 item>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid2>
          )}
          {successMessage && (
            <Grid2 item>
              <Alert severity="success">{successMessage}</Alert>
            </Grid2>
          )}
          <Grid2 item>
            <TextField
              required
              label="Current Password"
              type="password"
              fullWidth
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Grid2>
          <Grid2 item>
            <TextField
              required
              label="New Password"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid2>
          <Grid2 item>
            <TextField
              required
              label="Confirm New Password"
              type="password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid2>
          <Grid2 item container spacing={2} justifyContent="flex-end">
            <Grid2 item>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
            </Grid2>
            <Grid2 item>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
