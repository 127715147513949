import React, { useEffect, useState, Children } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, { marker } from "leaflet";
import vesselImg from "../../../Assets/Images/new_arrow_pointer.png";
import noImg from "../../../Assets/Images/FMS/vessel_fms_icon.png";
// import vesselImg from "../Assets/Images/ship-solid-red-resized.png";
// import { Button } from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import 'leaflet-rotatedmarker';
import 'leaflet-ant-path';
import { Box, Card, Button, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Switch from '@mui/material/Switch'; 
import FormControlLabel from '@mui/material/FormControlLabel';
import CardMedia from '@mui/material/CardMedia';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function FMSOpenSeaMap({ markerChild, isTracking = false, onToggleBackupGPS }) {
    const [vesselName, setVesselName] = useState();
    const [companyName, setCompanyName] = useState();
    const [vesselType, setVesselType] = useState();
    const [locationData, setLocationData] = useState();
    const defaultPosition = [-6.12747410563145, 106.85523275294094];
    const [checked, setChecked] = useState(false);
    const [checkedSettings, setCheckedSettings] = useState(false);
    const [useBackupGPS, setUseBackupGPS] = useState(false); 
    // const [mostRecentWeatherMap, setMostRecentWeatherMap] = useState();
    const [cog, setCog] = useState(0);
    const [, forceUpdate] = React.useReducer(x => x + 1, 0)

    // const RADAR_MAPS_URL = "https://api.rainviewer.com/public/weather-maps.json";

    useEffect(() => {
        console.log('markerChild==========>', markerChild);

    }, [markerChild]);

    // useEffect(() => {
    //     getMostRecentWeatherMap();
    // }, [])

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    // const getMostRecentWeatherMap = async () => {
    //     const res = await fetch(RADAR_MAPS_URL);
    // };

    const AntPathComponent = ({ marker }) => {
        if (isTracking) {
            // console.log(marker);
            const map = useMap();
            let route = marker.map(marker => marker.props.position);
            useEffect(() => {
                const path = L.polyline.antPath(route, {
                    delay: 800,
                    dashArray: [20, 20],
                    weight: 2,
                    color: "#0000FF",
                    pulseColor: "#FFFFFF",
                    paused: false,
                    reverse: false,
                    hardwareAccelerated: true
                });

                path.addTo(map);
                //   map.fitBounds(path.getBounds());

                return () => {
                    map.removeLayer(path);
                };

            }, [map, route]);
        } else {
            console.log(marker);
            marker.forEach((markerEl) => {
                const map = useMap();
                let route = markerEl.map(marker => marker.props.position);
                useEffect(() => {
                    const path = L.polyline.antPath(route, {
                        delay: 800,
                        dashArray: [20, 20],
                        weight: 2,
                        color: "#0000FF",
                        pulseColor: "#FFFFFF",
                        paused: false,
                        reverse: false,
                        hardwareAccelerated: true
                    });

                    path.addTo(map);
                    //   map.fitBounds(path.getBounds());

                    return () => {
                        map.removeLayer(path);
                    };

                }, [map, route]);
            });

        }

        return null;
    };


    const ZoomToMarkerButton = ({ position }) => {
        const map = useMap();

        const zoomToMarker = () => {

            map.setView([position.latitude, position.longitude], 20);
        };

        return (
            <IconButton aria-label="delete" size="large" sx={{
                color: '#1E3A8A',
                transform: `scale(${1.5}, ${1.5})`,
                paddingRight: 3
            }} onClick={zoomToMarker}>
                <SearchIcon />
            </IconButton>

        );
    };

    const renderListMarkerAIS = () => {
        if (markerChild) {
            if (markerChild.listMarker) {
                const listMarkerAIS = markerChild.listMarker.map((d) => {
                    if(!d.props.isBackup){
                        return d;
                    }});
                return (listMarkerAIS);
            }
        }
    }

    const renderListMarkerBackup = () => {
        if (markerChild) {
            if (markerChild.listMarker) {
                const listMarkerBackup = markerChild.listMarker.map((d) => {
                    if(d.props.isBackup){
                        return d;
                    }});
                return (listMarkerBackup);
            }
        }
    }

    const renderAnPath = () => {
        if (markerChild) {
            if (markerChild.listLine) {
                return <AntPathComponent marker={markerChild.listLine} />
            }
        }
    }

    const handleChange = () => {
        setChecked((prev) => !prev);
        setCheckedSettings(false);
    };

    const handleChangeSettings = () => {
        setCheckedSettings((prev) => !prev);
        setChecked(false);
    };

    const handleBackupGPSToggle = (event) => {
        const isChecked = event.target.checked;
        setUseBackupGPS(isChecked);

        if (onToggleBackupGPS) {
            onToggleBackupGPS(isChecked);
        }
    };


    const renderListVessel = (isBackupGPSUsed) => {
        if (markerChild && markerChild.listMarker && markerChild.listVessel) {
            console.log("RE RENDER LIST VESSEL", markerChild.listVessel);
            return markerChild.listMarker.map((marker, index) => {
                let vessel = markerChild.listVessel.find(
                    v => v.vessel_id === marker.props.vessel_id
                );

                if(!vessel) return null;
                

                return (
                    <div style={{ position: "absolute", top: "100px", left: "3px", zIndex: 1000 }}>
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: 1,
                                bgcolor: "#1E3A8A",
                                ml: 1,
                                boxShadow: 5,
                            }}
                        >
                            <IconButton
                                aria-label="toggle-vessels"
                                size="medium"
                                sx={{ color: "white" }}
                                onClick={handleChange}
                            >
                                <DirectionsBoatIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                        <Box
                        sx={{
                            width: 40,
                            height: 40,
                            borderRadius: 1,
                            bgcolor: "#1E3A8A",
                            ml: 1,
                            boxShadow: 5,
                            mt: 1
                        }}
                    >
                        <IconButton
                            aria-label="toggle-settings"
                            size="medium"
                            sx={{ color: "white" }}
                            onClick={handleChangeSettings}
                        >
                            <SettingsIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                        <div style={{ position: "absolute", left: 60, top: 0 }}>
                            <Collapse in={checked}>
                                <Box
                                    sx={{
                                        "& > :not(style)": {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            maxHeight: 600,
                                            width: 380,
                                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            borderRadius: 2,
                                            flexWrap: "wrap",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            '& > :not(style)': {
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                // height: 600,
                                                width: 350,
                                                margin: 10
                                            },
                                        }}
                                    >
                                        {
                            markerChild.listMarker.map((vessel, index) => {
                                let detail = null;
                                // console.log("ISBACKUPGPSUSED VESSEL LIST", isBackupGPSUsed);
                                if(isBackupGPSUsed){
                                    // console.log("ISBACKUPUSED INI TRUE");
                                    detail = markerChild.listVessel.find((value) => value.id === vessel.props.vesselId && vessel.props.isBackup);
                                }else{
                                    // console.log("ISBACKUPUSED INI FALSE");
                                    detail = markerChild.listVessel.find((value) => value.id === vessel.props.vesselId && !vessel.props.isBackup)
                                    // console.log("DETAIL", detail);
                                }
                                if(!detail) return;
                                return(
                                    <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    height: 140,
                                                    width: 350,
                                                    border: '1px solid #CBD5E1',
                                                    borderRadius: 5,
                                                    margin: '3px'
                                                }}>
                                                <div style={{ flex: 1, width: '100%', height: '100%', display: 'flex', justifyContent: "center", alignItems: 'center' }}> 
                                                    <LazyLoadImage
                                                        src={detail.base64_image !== null ? detail.base64_image : noImg}
                                                        style={{
                                                            display: { xs: 'none', md: 'flex' },
                                                            width: 120,
                                                            // height: 40,
                                                            marginRight: 3,
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ flex: 3, width: '100%', height: '100%' }}>
                                                    <CardContent>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ fontWeight: 'bold', color: 'text.primary', fontSize: 16 }}>
                                                                {detail.vessel_name}
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                                                <ZoomToMarkerButton position={{ latitude: vessel.props.position[0], longitude: vessel.props.position[1] }} />
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '10px' }}>
                                                            <Typography gutterBottom sx={{ fontWeight: 'bold', color: 'text.primary', fontSize: 14 }}>
                                                                {detail.vessel_type_name}
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                IMO Number: {detail.imo_number}
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </div>


                                            </div>
                                )
                            })
                        }
                                    </Box>
                                </Box>
                            </Collapse>
                            <Collapse in={checkedSettings}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    width: 380,
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    padding: 2,
                                }}
                            >
                                <Typography sx={{ fontSize: 16, fontWeight: 'bold', mb: 2 }}>
                                    Options
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={useBackupGPS}
                                            onChange={handleBackupGPSToggle}
                                        />
                                    }
                                    label="Use Backup GPS"
                                />
                            </Box>
                        </Collapse>
                        </div>
                    </div>
                );
            });
        }
        return null;
    };


    return (
        <>
            <MapContainer style={{ flex: 1, width: '100%' }} center={defaultPosition} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {renderListMarkerAIS()}
                {useBackupGPS && renderListMarkerBackup()}
                {/* {renderPolyline()} */}
                {renderListVessel(useBackupGPS)}

                {/* {renderAnPath()} */}

                {/* <TileLayer
                    attribution="RainViewer.com"
                    url={`https://tilecache.rainviewer.com${mostRecentWeatherMap}/256/{z}/{x}/{y}/2/1_1.png`}
                    opacity={0.6}
                    zIndex={2}
                /> */}

            </MapContainer>
        </>
    );
}
