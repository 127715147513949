// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-container{
    filter: drop-shadow(0px 0px 14px #000000);

}

.mainContainerFMSAdmin{
    width: 100%;
    height: 100vh;
    /* border: 2px red solid; */
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.childMainContainerFMSAdmin{
    width: 100%;
    height: 92vh;
    /* border: 2px yellow solid; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.childMainContainerFMSAdmin-BODY{
    width: 100%;
    height: 100%;
    /* border: 2px red solid; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/v2/Admin/Pages/FMSAdminContainerPage/index.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;;AAE7C;;AAEA;IACI,WAAW;IACX,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;IACzB,aAAa;IACb,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".drawer-container{\n    filter: drop-shadow(0px 0px 14px #000000);\n\n}\n\n.mainContainerFMSAdmin{\n    width: 100%;\n    height: 100vh;\n    /* border: 2px red solid; */\n    background-color: #FFFFFF;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n}\n\n.childMainContainerFMSAdmin{\n    width: 100%;\n    height: 92vh;\n    /* border: 2px yellow solid; */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.childMainContainerFMSAdmin-BODY{\n    width: 100%;\n    height: 100%;\n    /* border: 2px red solid; */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
