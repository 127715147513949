import React, { useEffect, useState, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../../AppV2.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isTokenValid, findVesselByVesselId } from "../../../../Helpers/ApplicationHelpers";
import FMSTheme from "../../../FMSTheme";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import './index.css'
import { getSMSDashboardPagesV2 } from "../../../../Helpers/ApplicationHelpers";
const FMSMainEngine = React.lazy(() => import("./MainEngine"));
const FMSAuxiliaryEngine = React.lazy(() => import("./AuxiliaryEngine"));
const FMSGearBox = React.lazy(() => import("./GearBox"));
const FMSMainEnginePerformance = React.lazy(() => import("./EnginePerformance"));
const FMSAuxiliaryEnginePerformance = React.lazy(() => import("./AuxiliaryEnginePerformance"));
const FMSFuelConsumption= React.lazy(() => import("./FuelConsumption"));
const FMSTankLevel= React.lazy(() => import("./TankLevel"));

import { findAllVesselById } from "../../../../Helpers/ApplicationHelpers";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function FMSVesselSMSDashboard({state}) {
    const { t, i18n } = useTranslation();
    const [selectedTabIndex, setSelectedTabIndex] = useState();
    const [selectedTab, setSelectedTab] = useState();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    let location = useLocation();
    const [vesselId, setVesselId] = useState();
    const [dashboardPages, setDashboardPages] = useState([]);
    // const state = location.state;
    
    // List of tabs and corresponding components
    const tabs = {
        "/Vessel/SMS/MainEngine" : {Component: FMSMainEngine, State: {vesselId: state.vesselId}},
        "/Vessel/SMS/AuxiliaryEngine" : {Component: FMSAuxiliaryEngine, State: {vesselId: state.vesselId}},
        "/Vessel/SMS/Gearbox" : {Component: FMSGearBox, State: {vesselId: state.vesselId}},
        "/Vessel/SMS/TankLevel" : {Component: FMSTankLevel, State: {vesselId: state.vesselId}},
        "/Vessel/SMS/MainEnginePerformance" : {Component: FMSMainEnginePerformance, State: {vesselId: state.vesselId}},
        "/Vessel/SMS/AuxiliaryEnginePerformance" : {Component: FMSAuxiliaryEnginePerformance, State: {vesselId: state.vesselId}},
        "/Vessel/SMS/FuelConsumption" : {Component: FMSFuelConsumption, State: {vesselId: state.vesselId}},
    };

    useEffect(() => {
        loadUserSMSDashboardPages();
    }, []);

    useEffect(() => {
        if(dashboardPages.length > 0){
            dashboardPages.forEach((d) => {
                const menuPath = d.menu_path;
                const tab = tabs[menuPath];
                if(tab){
                    tab.State.accountXPageMenuId = d.id;
                }
            });
            const firstItem = dashboardPages[0].menu_path;

            if(firstItem){
                setSelectedTabIndex({... tabs[firstItem], MenuPath: firstItem});
            }
        }
    }, [dashboardPages]);


    // useEffect(() => {
    //     if(vesselId){
    //         loadVessel();
    //     }
    // }, [vesselId]);

    const handleChange = (event, newValue) => {
        console.log("TAB", newValue);
        setSelectedTabIndex({...tabs[newValue], MenuPath: newValue});
    };

    // const loadVessel = async () => {
    //     try {
    //         let result = await findAllVesselById(cookies.token, vesselId);
    //         setVessel(result);
    //     }
    //     catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadUserSMSDashboardPages = async () => {
        try{
            const result = await getSMSDashboardPagesV2(cookies.token, cookies.languageId);
            setDashboardPages(result);
        }catch(exception){
            console.error(exception);
        }
    }

    return (
        <div className='mainContainerFMS'>
            <div className='childMainContainerFMS'>
                <div className='childMainContainerFMS-BODY overflow-auto'>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            display: "flex",
                            width: "100%",
                            border: 1,
                            borderColor: "divider",
                        }}
                        className="customScrollbarTabs"
                    >
                        {selectedTabIndex && <><Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={selectedTabIndex.MenuPath}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: "divider" }}
                        >
                            {dashboardPages.map((page, index) => (
                                <Tab key={index} label={page.page_name} value={page.menu_path} />
                            ))}
                        </Tabs>
                        <Container fluid>
                            <TabPanel value={selectedTabIndex.menu_path} key={selectedTabIndex.menu_path}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <selectedTabIndex.Component state={selectedTabIndex.State}/>
                                </Suspense>
                            </TabPanel>
                        </Container></>}
                        
                    </Box>
                </div>
            </div>
        </div>
    );
}
