import './index.css';
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isFMSAdminTokenValid, getInventoryBrand } from "../../../../Helpers/ApplicationHelpers";
import {
    findAllInventoryMasterV2, getInventoryGroup, getInventorySubGroupByGroupId, getInventoryMaterial, getInventoryUnitType, insertUpdateInventoryMaster
    , getInventoryMasterById, findAllInventoryMasterPaginatedV2
} from "../../../../Helpers/InventoryHelpers";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Trans, useTranslation } from "react-i18next";
// import FMSAdminAuthenticateToken from '../../../../Components/v2/FMSAdminAuthenticateToken';
import Loading from "../../../../Components/v2/FMSLoading/index";
import EnhancedTable from '../../../../Components/v2/FMSTable';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Modal, Button, TextField, Typography, Box, Stack, FormControl, InputLabel, Select, Checkbox, FormControlLabel, MenuItem, TableCell } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container } from 'react-bootstrap';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ServerSideTableWithSearch from '../../../../Components/v2/FMSTableServerSide';

import { deleteInventoryMaster } from '../../../../Helpers/InventoryHelpers';

export default function AdminInventoryMasterPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [listInventoryMaster, setListInventoryMaster] = useState([]);
    const [openInputModal, setOpenInputModal] = useState(false);
    const [listInventoryGroup, setListInventoryGroup] = useState([]);
    const [listInventorySubGroup, setListInventorySubGroup] = useState([]);
    const [listInventoryMaterial, setListInventoryMaterial] = useState([]);
    const [listInventoryBrand, setListInventoryBrand] = useState([]);
    const [listUnitType, setListUnitType] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedSubGroup, setSelectedSubGroup] = useState('');
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedUnitType, setSelectedUnitType] = useState('');
    const [selectedRow, setSelectedRow] = useState();
    const [saveButtonInventoryMaster, setSaveButtonInventoryMaster] = useState(false);
    const [shouldLoadDataToModal, setShouldLoadDataToModal] = useState(false);
    const [detailInventoryMaster, setDetailInventoryMaster] = useState({
        id: 0,
        inventory_master_name: "",
        unit: "",
        reference_price: 0,
        brand: "",
        model: "",
        quantity: 0,
        inventory_master_description: "",
        inventory_master_note: "",
        inventory_unit_id: "",
        language_id: cookies.languageId,
        ext_customer_id: cookies.extCustomerId,
        is_consumeable: false,
        lead_time: 0,
        buffer_stock: 0,
        inventory_brand_id: "",
        inventory_material_id: "",
        inventory_master_alias: "",
        inventory_group_id: "",
        inventory_subgroup_id: "",
        is_production_material: false,
        unit_name: "",
        unit_code: "",
        inventory_group_name: "",
        inventory_group_code: "",
        inventory_subgroup_name: "",
        inventory_subgroup_code: "",
        inventory_material_name: "",
        inventory_material_code: "",
        brand_name: "",
        brand_code: "",
        part_number: "",
        weight: 0,
        parameters: []
    });


    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const handleChangePage = useCallback(async (page, rowsPerPage, orderBy, order, searchQuery) => {
        const descending = order === "desc";
        const result = await findAllInventoryMasterPaginatedV2(
            cookies.token,
            cookies.languageId,
            cookies.extCustomerId,
            page,
            rowsPerPage,
            orderBy,
            descending,
            searchQuery
        );
        if (result) {
            setRows(result.rows);
            setTotalRows(parseInt(result.totalRows));
        }
    }, [cookies.token, cookies.languageId, cookies.extCustomerId]);

    const columnsDataGrid = [
        { id: "inventory_master_name", label: "Name", flex: 1, sortable: true, filterable: false },
        { id: "part_number", label: "Part Number", flex: 1, sortable: false, filterable: false },
        { id: "brand_name", label: "Brand", flex: 1, sortable: false, filterable: false },
        { id: "model", label: "Model", flex: 1, sortable: false, filterable: false },
        { id: "view", label: "View", flex: 1, sortable: false, filterable: false, renderCell: (row) => { return <Button key={row.id} style={{ marginRight: '15px' }} onClick={(event) => { handleRowClick(row) }}><OpenInNewIcon size={40} color="#64748B" /></Button> } },
        { id: "delete", label: "Delete", sortable: false, renderCell: (row) => { return <Button key={row.id} style={{ marginRight: '15px' }} onClick={(event) => { handleDeleteRow(row.id) }}><DeleteIcon size={40} color="#64748B" /></Button> } },
    ]

    const columnsForTable = [
        { id: 'inventory_master_name', label: 'Name' },
        { id: 'part_number', label: 'Part Number' },
        { id: 'brand', label: 'Brand' },
        { id: 'model', label: 'Model' },
        { id: 'view_button', label: 'View' },
        { id: 'delete_button', label: 'Delete' },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '95vh',
        bgcolor: 'background.paper',
        // border: '1px solid #1E3A8A',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
        overflow: 'auto'
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isFMSAdminTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/FMS");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryMaster();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (detailInventoryMaster) {
            console.log('detailInventoryMaster', detailInventoryMaster)
            if (detailInventoryMaster.inventory_group_id !== "" && detailInventoryMaster.inventory_subgroup_id !== "" && detailInventoryMaster.inventory_material_id !== "" && detailInventoryMaster.model !== "") {
                setSaveButtonInventoryMaster(true);
            }

            if (detailInventoryMaster.id !== 0) {
                handleOpenInputModal();
            }
        }

        // if(selectedRow){
        //     if(selectedRow.id){
        //         const selectGroup = listInventoryGroup.find(item => item.id === selectedRow&&selectedRow.inventory_group_id);
        //         setSelectedGroup(selectGroup);
        //         const selectSubGroup = listInventorySubGroup.find(item => item.id === selectedRow&&selectedRow.inventory_subgroup_id);
        //         setSelectedSubGroup(selectSubGroup);
        //         const selectBrand = listInventoryBrand.find(item => item.id === selectedRow&&selectedRow.inventory_brand_id);
        //         setSelectedBrand(selectBrand);
        //         const selectedMaterial = listInventoryMaterial.find(item => item.id === selectedRow&&selectedRow.inventory_material_id);
        //         setSelectedMaterial(selectedMaterial);
        //         const selectedUnit = listUnitType.find(item => item.id === selectedRow&&selectedRow.inventory_unit_id);
        //         setSelectedUnitType(selectedUnit);
        //     }
        // }
    }, [detailInventoryMaster]);


    useEffect(() => {
        if (openInputModal) {
            loadGroup();
        }
    }, [openInputModal])

    useEffect(() => {
        if (selectedGroup) {
            if (selectedGroup.id) {
                setDetailInventoryMaster({ ...detailInventoryMaster, inventory_group_id: selectedGroup.id, inventory_group_name: selectedGroup.inventory_group_name, inventory_group_code: selectedGroup.inventory_group_code });
                loadSubGroup();
            }
        }
    }, [selectedGroup])

    useEffect(() => {
        if (selectedSubGroup) {
            setDetailInventoryMaster({ ...detailInventoryMaster, inventory_subgroup_id: selectedSubGroup.id, inventory_subgroup_name: selectedSubGroup.inventory_subgroup_name, inventory_subgroup_code: selectedSubGroup.inventory_subgroup_code });
            loadMaterial();
        }
    }, [selectedSubGroup])

    useEffect(() => {
        if (selectedMaterial) {
            setDetailInventoryMaster({ ...detailInventoryMaster, inventory_material_id: selectedMaterial.id, inventory_material_name: selectedMaterial.inventory_material_name, inventory_material_code: selectedMaterial.inventory_material_code });
            loadInventoryBrand();
        }
    }, [selectedMaterial])

    useEffect(() => {
        if (selectedBrand) {
            let newName = selectedBrand && selectedBrand.brand_name;
            setDetailInventoryMaster({ ...detailInventoryMaster, inventory_brand_id: selectedBrand.id, brand_name: selectedBrand.brand_name, brand_code: selectedBrand.brand_code, inventory_master_name: newName });
            loadUnitType();
        }
    }, [selectedBrand])

    useEffect(() => {
        if (selectedUnitType) {
            setDetailInventoryMaster({ ...detailInventoryMaster, inventory_unit_id: selectedUnitType && selectedUnitType.id, unit: selectedUnitType && selectedUnitType.unit_name });
        }
    }, [selectedUnitType]);

    useEffect(() => {
        if (listInventoryGroup) {
            console.log("LIST GROUP", listInventoryGroup);
            if(detailInventoryMaster){
                setSelectedGroup(listInventoryGroup.find(p => p.id === detailInventoryMaster.inventory_group_id) || null);
            }
            

        }
    }, [listInventoryGroup]);

    useEffect(() => {
        if (listInventorySubGroup) {
            console.log("LIST SUBGROUP", listInventorySubGroup);
            if (selectedGroup) {
                setSelectedSubGroup(listInventorySubGroup.find(p => p.id === detailInventoryMaster.inventory_subgroup_id) || null);
            }
        }
    }, [listInventorySubGroup]);

    useEffect(() => {
        if(listInventoryMaterial){
            if(selectedSubGroup){
                setSelectedMaterial(listInventoryMaterial.find(p => p.id === detailInventoryMaster.inventory_material_id || null));
            }
        }
    }, [listInventoryMaterial]);

    useEffect(() => {
        if(listInventoryBrand){
            if(selectedMaterial){
                setSelectedBrand(listInventoryBrand.find(p => p.id === detailInventoryMaster.inventory_brand_id || null));
            }
        }
    }, [listInventoryBrand]);


    const submitInventoryMaster = () => {
        saveInventoryMaster();
    }


    const saveInventoryMaster = async () => {
        console.log('save inventroy master')

        let response = await insertUpdateInventoryMaster(cookies.token, detailInventoryMaster);
        if (response.error_code === 0) {
            alert('Successfully saved data');
            handleCloseInputModal();
            loadInventoryMaster();
        }
        else {
            alert('Failed to save data. Please Try again');
        }
    }

    const loadInventoryMaster = async () => {
        try {
            // let result = await findAllInventoryMasterV2(cookies.token, cookies.languageId, cookies.extCustomerId, "", 0, 100000, "inventory.inventory_master_name", true, "", false);
            // setListInventoryMaster(result);
            const result2 = await findAllInventoryMasterPaginatedV2(cookies.token, cookies.languageId, cookies.extCustomerId, 0, 5, "created_date", true, undefined);
            if (result2) {
                setRows(result2.rows);
                setTotalRows(result2.totalRows);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadGroup = async () => {
        try {
            let listGroup = await getInventoryGroup(cookies.token, cookies.languageId, 0, 1000000);
            setListInventoryGroup(listGroup);

        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadSubGroup = async () => {
        try {
            let listSubGroup = await getInventorySubGroupByGroupId(cookies.token, cookies.languageId, cookies.extCustomerId, selectedGroup && selectedGroup.id, 0, 1000000);
            setListInventorySubGroup(listSubGroup);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadMaterial = async () => {
        try {
            let listMaterial = await getInventoryMaterial(cookies.token, 0, 1000000);
            setListInventoryMaterial(listMaterial);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryBrand = async () => {
        try {
            let response = await getInventoryBrand(cookies.token);
            setListInventoryBrand(response);
        } catch (exception) {

        }
    }

    const loadUnitType = async () => {
        try {
            let response = await getInventoryUnitType(cookies.token);
            console.log(response)
            setListUnitType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const resetDetailInventoryUpload = async () => {
        console.log("RESETTING");
        setDetailInventoryMaster(
            {
                ...detailInventoryMaster,
                id: 0,
                inventory_master_name: "",
                unit: "",
                reference_price: 0,
                brand: "",
                model: "",
                quantity: 0,
                inventory_master_description: "",
                inventory_master_note: "",
                inventory_unit_id: "",
                language_id: cookies.languageId,
                ext_customer_id: cookies.extCustomerId,
                is_consumeable: false,
                lead_time: 0,
                buffer_stock: 0,
                inventory_brand_id: "",
                inventory_material_id: "",
                inventory_master_alias: "",
                inventory_group_id: "",
                inventory_subgroup_id: "",
                is_production_material: false,
                unit_name: "",
                unit_code: "",
                inventory_group_name: "",
                inventory_group_code: "",
                inventory_subgroup_name: "",
                inventory_subgroup_code: "",
                inventory_material_name: "",
                inventory_material_code: "",
                brand_name: "",
                brand_code: "",
                part_number: "",
                weight: 0,
                parameters: []
            }
        );
        setSelectedGroup({});
        setSelectedSubGroup({});
        setSelectedMaterial({});
        setSelectedBrand({});
        setSelectedUnitType({});
    }

    // const showDataToModal = (row) => {
    //     setDetailInventoryMaster((prevState) => ({
    //         ...prevState,
    //         ...Object.keys(prevState).reduce((acc, key) => {
    //             acc[key] = row[key] !== null && row[key] !== undefined ? row[key] : prevState[key];
    //             return acc;
    //         }, {})
    //     }));
    // }

    const handleOpenInputModal = () => {
        setOpenInputModal(true);
    }

    const handleCloseInputModal = () => {
        // setLoadDocType(false);
        setOpenInputModal(false);
        resetDetailInventoryUpload();
    }

    const addNewInventoryParameter = () => {
        let newParameter = {
            id: "",
            parameter_name: "",
            parameter_value: "",
            parameter_description: "",
        };
        if (!detailInventoryMaster.parameters) {
            let parameters = [];
            parameters.push(newParameter);
            setDetailInventoryMaster({ ...detailInventoryMaster, parameters: parameters });
        }
        else {
            let parameters = detailInventoryMaster.parameters;
            parameters.push(newParameter);
            setDetailInventoryMaster({ ...detailInventoryMaster, parameters: parameters });
        }
    }

    const handleRowClick = async (row) => {
        console.log("ROW CLICK", row);
        try {
            let response = await getInventoryMasterById(cookies.token, row.id, cookies.languageId);
            if (response) {
                setDetailInventoryMaster({
                    id: response.id,
                    inventory_master_name: response.inventory_master_name,
                    unit: response.unit,
                    reference_price: response.reference_price,
                    brand: response.brand,
                    model: response.model,
                    quantity: response.quantity,
                    inventory_master_description: response.inventory_master_description,
                    inventory_master_note: response.inventory_master_note,
                    inventory_unit_id: response.inventory_unit_id,
                    language_id: response.language_id,
                    ext_customer_id: response.ext_customer_id,
                    is_consumeable: response.is_consumeable,
                    lead_time: response.lead_time,
                    buffer_stock: response.buffer_stock,
                    inventory_brand_id: response.inventory_brand_id,
                    inventory_material_id: response.inventory_material_id,
                    inventory_master_alias: response.inventory_master_alias,
                    inventory_group_id: response.inventory_group_id,
                    inventory_subgroup_id: response.inventory_subgroup_id,
                    is_production_material: response.is_production_material,
                    unit_name: response.unit_name,
                    unit_code: response.unit_code,
                    inventory_group_name: response.inventory_group_name,
                    inventory_group_code: response.inventory_group_code,
                    inventory_subgroup_name: response.inventory_subgroup_name,
                    inventory_subgroup_code: response.inventory_subgroup_code,
                    inventory_material_name: response.inventory_material_name,
                    inventory_material_code: response.inventory_material_code,
                    brand_name: response.brand_name,
                    brand_code: response.brand_code,
                    part_number: response.part_number,
                    weight: response.weight,
                    parameters: response.parameters,

                });
            }


        } catch (exception) {
            console.log(exception);
        }
    }

    const deleteInventoryMasterById = async (id) => {
        try {
            const result = await deleteInventoryMaster(cookies.token, id);
            loadInventoryMaster();
        } catch (exception) {
            console.error(exception);
        }
    }

    const handleDeleteRow = (id) => {
        if (window.confirm(`Are you sure you want to delete this?`)) {
            deleteInventoryMasterById(id);
        }
    };

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY overflow-auto'>
                        <Container fluid>
                            <div className='vesselEquipmentMainContainer'>
                                <div className='invAddButtonContainer'>
                                    <Button variant="outlined"
                                        startIcon={<NoteAddIcon />}
                                        onClick={() => {
                                            handleOpenInputModal();
                                        }}>
                                        Add New
                                    </Button>
                                </div>
                                <ServerSideTableWithSearch
                                    rows={rows}
                                    totalRows={totalRows}
                                    columns={columnsDataGrid}
                                    defaultSortField={"created_date"}
                                    defaultSortOrder={"desc"}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    handleChangePage={handleChangePage}
                                />
                                {/* <ServerSideDataGrid 
                                rows={rows}
                                totalRows={totalRows}
                                columns={columnsDataGrid}
                                defaultSortField={"created_date"}
                                defaultSortOrder={"desc"}
                                rowsPerPageOptions = {[5, 10, 25]}
                                handleChangePage={handleChangePage}
                            /> */}
                                {/* <EnhancedTable data={listInventoryMaster} columns={columnsForTable} onRowClick={handleRowClick} deletedId={handleDeleteRow} /> */}
                                {/* <div className='childMainContainerFMSAdmin'>
                                <div className='childMainContainerFMSAdmin-BODY'>
                                    <div className='fmsEnhancedTableContainerInventory'>
                                        <EnhancedTable data={listInventoryMaster} columns={columnsForTable}/>
                                    </div>
                                </div>
                            </div> */}
                            </div>

                            <Modal
                                open={openInputModal}
                                onClose={handleCloseInputModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ width: '100%' }}>
                                        ADD NEW INVENTORY MASTER
                                    </Typography>
                                    <hr />
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Group</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={detailInventoryMaster && detailInventoryMaster.inventory_group_id}
                                                label="Group"
                                                onChange={(e) => {
                                                    const selected = listInventoryGroup.find(item => item.id === e.target.value);
                                                    setSelectedGroup(selected);
                                                }}
                                            >
                                                {listInventoryGroup && listInventoryGroup.length > 0 ? (
                                                    listInventoryGroup.map((value) => (
                                                        <MenuItem key={value.id} value={value.id}>
                                                            {value.inventory_group_name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value="" disabled>
                                                        No options available
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sub Group</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={detailInventoryMaster && detailInventoryMaster.inventory_subgroup_id}
                                                label="Sub Group"
                                                onChange={(e) => {
                                                    const selected = listInventorySubGroup.find(item => item.id === e.target.value);
                                                    setSelectedSubGroup(selected);
                                                }}
                                            >
                                                {listInventorySubGroup && listInventorySubGroup.length > 0 ? (
                                                    listInventorySubGroup.map((value) => (
                                                        <MenuItem key={value.id} value={value.id}>
                                                            {value.inventory_subgroup_name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value="" disabled>
                                                        No options available
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Material</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={detailInventoryMaster && detailInventoryMaster.inventory_material_id}
                                                label="Material"
                                                onChange={(e) => {
                                                    const selected = listInventoryMaterial.find(item => item.id === e.target.value);
                                                    setSelectedMaterial(selected);
                                                }}
                                            >
                                                {listInventoryMaterial && listInventoryMaterial.length > 0 ? (
                                                    listInventoryMaterial.map((value) => (
                                                        <MenuItem key={value.id} value={value.id}>
                                                            {value.inventory_material_name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value="" disabled>
                                                        No options available
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={detailInventoryMaster && detailInventoryMaster.inventory_brand_id}
                                                label="Brand"
                                                onChange={(e) => {
                                                    const selected = listInventoryBrand.find(item => item.id === e.target.value);
                                                    setSelectedBrand(selected);
                                                }}
                                            >
                                                {listInventoryBrand && listInventoryBrand.length > 0 ? (
                                                    listInventoryBrand.map((value) => (
                                                        <MenuItem key={value.id} value={value.id}>
                                                            {value.brand_name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value="" disabled>
                                                        No options available
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.model}
                                            label="Model"
                                            onChange={(e) => {
                                                let newName = `${detailInventoryMaster && detailInventoryMaster.brand_name || ""} ${e.target.value}`;
                                                setDetailInventoryMaster((prevState) => ({
                                                    ...prevState,
                                                    model: e.target.value,
                                                    inventory_master_name: newName,
                                                }));
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <TextField
                                            disabled={true}
                                            value={detailInventoryMaster && detailInventoryMaster.inventory_master_name}
                                            label={detailInventoryMaster && detailInventoryMaster.inventory_master_name ? "" : ""}
                                            onChange={(e) => {
                                                // let newName = detailInventoryMaster&&detailInventoryMaster.brand_name + '' + detailInventoryMaster&&detailInventoryMaster.model;
                                                // setDetailInventoryMaster({ ...detailInventoryMaster, inventory_master_name: newName });
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.lead_time}
                                            label="Lead Times (Day)"
                                            onChange={(e) => {
                                                setDetailInventoryMaster({ ...detailInventoryMaster, lead_time: e.target.value })
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.buffer_stock}
                                            label="Buffer Stock"
                                            onChange={(e) => {
                                                setDetailInventoryMaster({ ...detailInventoryMaster, buffer_stock: e.target.value })
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.weight}
                                            label="Weight (g)"
                                            onChange={(e) => {
                                                setDetailInventoryMaster({ ...detailInventoryMaster, weight: e.target.value })
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Unit of Goods</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={detailInventoryMaster && detailInventoryMaster.inventory_unit_id}
                                                label="Document Type"
                                                onChange={(e) => {
                                                    const selected = listUnitType.find(item => item.id === e.target.value);
                                                    setSelectedUnitType(selected);
                                                }}
                                            >
                                                {listUnitType && listUnitType.length > 0 ? (
                                                    listUnitType.map((value) => (
                                                        <MenuItem key={value.id} value={value.id}>
                                                            {value.unit_name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value="" disabled>
                                                        No options available
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.part_number}
                                            label="Part Number"
                                            onChange={(e) => {
                                                setDetailInventoryMaster({ ...detailInventoryMaster, part_number: e.target.value })
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.inventory_master_description}
                                            multiline={true}
                                            rows={2}
                                            label="Description"
                                            onChange={(e) => {
                                                setDetailInventoryMaster({ ...detailInventoryMaster, inventory_master_description: e.target.value })
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                        <TextField
                                            disabled={false}
                                            value={detailInventoryMaster && detailInventoryMaster.inventory_master_note}
                                            multiline={true}
                                            rows={2}
                                            label="Note"
                                            onChange={(e) => {
                                                setDetailInventoryMaster({ ...detailInventoryMaster, inventory_master_note: e.target.value })
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ width: '100%' }}>
                                        PARAMETER
                                    </Typography>
                                    <hr />
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        {
                                            detailInventoryMaster && detailInventoryMaster.parameters && detailInventoryMaster.parameters.map((param, index) => {
                                                return (
                                                    <Box
                                                        component="form"
                                                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField
                                                            disabled={false}
                                                            value={param.parameter_name ? param.parameter_name : ""}
                                                            label="Parameter Name"
                                                            onChange={(e) => {
                                                                let parameters = detailInventoryMaster.parameters;
                                                                parameters[index].parameter_name = e.target.value;
                                                                setDetailInventoryMaster({ ...detailInventoryMaster, parameters: parameters });
                                                            }}
                                                            sx={{
                                                                "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#000000",
                                                                },
                                                                width: '100%',
                                                                mr: 1
                                                            }}
                                                        />
                                                        <TextField
                                                            disabled={false}
                                                            value={param.parameter_value ? param.parameter_value : ""}
                                                            label="Parameter Value"
                                                            onChange={(e) => {
                                                                let parameters = detailInventoryMaster.parameters;
                                                                parameters[index].parameter_value = e.target.value;
                                                                setDetailInventoryMaster({ ...detailInventoryMaster, parameters: parameters });
                                                            }}
                                                            sx={{
                                                                "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#000000",
                                                                },
                                                                width: '50%',
                                                                mr: 1
                                                            }}
                                                        />
                                                        <TextField
                                                            disabled={false}
                                                            value={param.parameter_description ? param.parameter_description : ""}
                                                            label="Description"
                                                            onChange={(e) => {
                                                                let parameters = detailInventoryMaster.parameters;
                                                                parameters[index].parameter_description = e.target.value;
                                                                setDetailInventoryMaster({ ...detailInventoryMaster, parameters: parameters });
                                                            }}
                                                            sx={{
                                                                "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "#000000",
                                                                },
                                                                width: '50%',
                                                                mr: 1
                                                            }}
                                                        />
                                                        <DeleteIcon size={40}
                                                            color="#64748B"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                if (window.confirm(`Are you sure you want to delete this?`)) {
                                                                    let list = [...detailInventoryMaster.parameters];
                                                                    list.splice(index, 1);
                                                                    setDetailInventoryMaster({ ...detailInventoryMaster, parameters: list });
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Button variant="outlined"
                                            startIcon={<NoteAddIcon />}
                                            sx={{ width: '100%', height: '50px' }}
                                            onClick={() => {
                                                addNewInventoryParameter();
                                            }}>
                                            Add parameter
                                        </Button>
                                    </Box>
                                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                        <Button variant="outlined" startIcon={<CheckBoxIcon />}
                                            disabled={!saveButtonInventoryMaster}
                                            sx={{ width: '100%', height: '50px' }}
                                            onClick={() => { submitInventoryMaster(); }}>
                                            Save
                                        </Button>
                                        <Button variant="outlined" startIcon={<DisabledByDefaultIcon />}
                                            sx={{ width: '100%', height: '50px' }}
                                            onClick={() => { handleCloseInputModal() }}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Modal>

                            <Loading
                                loading={loading}
                                loadingText={`LOADING`}
                            />
                        </Container>

                    </div>
                </div>
            </div>
        </>
    );
}