import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../AppV2.css";
import { Trans, useTranslation } from "react-i18next";
import Loading from "../../../Components/v2/FMSLoading/index";
import {Box, Button, Grid2, Modal, Paper, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import noImg from "../../../Assets/Images/FMS/vessel_fms_icon.png";
import Container from '@mui/material/Container';
import ChangePasswordModal from '../../../Components/v2/FMSChangePasswordModal';
import { getUserDetailsV2 } from '../../../Helpers/ApplicationHelpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function FMSProfilePage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [profilePicture, setProfilePicture] = useState('');



    useEffect(() => {
        loadUserDetails();
    }, []);

    const loadUserDetails = async () => {
        try {
            const result = await getUserDetailsV2(cookies.token);
            console.log("RESULT", result);
            if(result.success){
                setUsername(result.data.login_username || "");
                setEmail(result.data.email || "");
                setCustomerName(result.data.customer_name || "");
            }
        } catch(exception) {
            console.error(exception);
        }
        setLoading(false);
    }

    const handleOnClickChangePasword = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY'>
                        <Container maxWidth="lg">
                            <Card sx={{  margin: 'auto', mt: 5 }}>
                                <CardHeader
                                    title="User Profile"
                                    sx={{
                                        '& .MuiCardHeader-title': {
                                          fontSize: '1.5rem', // Adjust title size
                                          fontWeight: 'bold',
                                        }
                                    }}
                                />
                                <CardContent>
                                    <Grid2 container spacing={2} direction={"column"}>
                                        <Grid2 justifyContent="center" alignItems="center" container direction={"column"}>
                                            <Grid2>
                                                <LazyLoadImage 
                                                    height={200}
                                                    width={200}
                                                    alt={"Profile Picture"}
                                                    placeholderSrc={noImg}
                                                    src={profilePicture || noImg}
                                                />
                                            </Grid2>
                                            <Grid2 alignItems="center">
                                                <Button disabled>Change Profile Picture</Button>
                                            </Grid2>
                                            
                                        </Grid2>

                                        <TextField
                                            label="Username"
                                            value={username}
                                            variant="filled"
                                            slotProps={{
                                                input: {
                                                    readOnly: true,
                                                },
                                            }}
                                        />

                                        <TextField
                                            label="Email"
                                            value={email}
                                            variant="filled"
                                            slotProps={{
                                                input: {
                                                    readOnly: true,
                                                },
                                            }}
                                        />

                                        <TextField
                                            label="Customer Name"
                                            value={customerName}
                                            variant="filled"
                                            slotProps={{
                                                input: {
                                                    readOnly: true,
                                                },
                                            }}
                                        />
                                    </Grid2>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={handleOnClickChangePasword}>Change Password</Button>
                                </CardActions>
                            </Card>
                        </Container>
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
            />
            <ChangePasswordModal
                token={cookies.token}
                open={open}
                handleClose={handleClose}
            />
        </>
    );
}