import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isFMSAdminTokenValid, findAllCustomerAdminPage } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { Trans, useTranslation } from "react-i18next";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Box,
    Pagination, FormControl, InputLabel, Select, MenuItem, CircularProgress} from '@mui/material'
import ServerSideTableWithSearch from '../../../../Components/v2/FMSTableServerSide';


export default function AdminCustomerPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);        // 1-based page indexing
    const [pageSize, setPageSize] = useState(10);
    const [error, setError] = useState(null);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const handleChangePage = useCallback(async (page, rowsPerPage, orderBy, order, searchQuery) => {
        const descending = order === "desc";
        const result = await findAllInventoryMasterPaginatedV2(
            cookies.token,
            cookies.languageId,
            cookies.extCustomerId,
            page,
            rowsPerPage,
            orderBy,
            descending,
            searchQuery
        );
        if (result) {
            setRows(result.rows);
            setTotalRows(parseInt(result.totalRows));
        }
    }, [cookies.token, cookies.languageId, cookies.extCustomerId]);

    const columnsDataGrid = [
        { id: "customer_id", label: "Name", flex: 1, sortable: true, filterable: false },
        { id: "part_number", label: "Part Number", flex: 1, sortable: false, filterable: false },
        { id: "brand_name", label: "Brand", flex: 1, sortable: false, filterable: false },
        { id: "model", label: "Model", flex: 1, sortable: false, filterable: false },
        { id: "view", label: "View", flex: 1, sortable: false, filterable: false, renderCell: (row) => { return <Button key={row.id} style={{ marginRight: '15px' }} onClick={(event) => { handleRowClick(row) }}><OpenInNewIcon size={40} color="#64748B" /></Button> } },
        { id: "delete", label: "Delete", sortable: false, renderCell: (row) => { return <Button key={row.id} style={{ marginRight: '15px' }} onClick={(event) => { handleDeleteRow(row.id) }}><DeleteIcon size={40} color="#64748B" /></Button> } },
    ]

    const loadCustomer = async () => {
        try {
            let result = await getCustomerV2(cookies.token, cookies.extCustomerId);
            console.log(result);
            setListCustomer(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div className='mainContainerFMSAdmin'>
                <div className='childMainContainerFMSAdmin'>
                    <div className='childMainContainerFMSAdmin-BODY'>
                        <div className='fmsEnhancedTableContainerAdmin'>
                           <ServerSideTableWithSearch
                                rows={rows}
                                totalRows={totalRows}
                                columns={columnsDataGrid}
                                defaultSortField={"created_date"}
                                defaultSortOrder={"desc"}
                                rowsPerPageOptions={[5, 10, 25]}
                                handleChangePage={handleChangePage}
                           />
                        </div>
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
                />
        </>
    );
}