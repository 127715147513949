import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function LinearProgressWithLabel({ normalizedValue, height, color, value, valueUnit }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: "100%",
          mr: 1,
          position: "relative",
          boxShadow: "0 4px 4px rgba(0, 0, 0, 0.2)",
          borderRadius: "5px",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={normalizedValue}
          sx={{
            height: height,
            borderRadius: "5px",
            backgroundColor: "#f8f8f8",
            "& .MuiLinearProgress-bar": {
              borderRadius: "5px",
              backgroundColor: color,
            },
          }}
        />
      </Box>
      {/* <Box sx={{ minWidth: 50, mr: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.primary", fontSize: 15 }}
        >
          {Math.round(value)} {valueUnit}
        </Typography>
      </Box> */}
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  normalizedValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  valueUnit: PropTypes.string,
};

LinearProgressWithLabel.defaultProps = {
  valueUnit: "",
};

export default function LinearWithValueLabel({
  value = 0,
  height = 16,
  minValue = 0,
  maxValue = 100,
  redRange = [95, 100],
  yellowRange = [80, 94],
  greenRange = [0, 79],
  valueUnit = "%",
  lowWarningAt = null,
  lowErrorAt = null,
  highWarningAt = null,
  highErrorAt = null
}) {
  // let normalizedValue = 0;
  const [normalizedValue, setNormalizedValue] = useState(0);
  const [color, setColor] = useState('#CCCCCC');

  useEffect(() => {
    if (value) {
      if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }


      let col;
      if (lowWarningAt != null && lowErrorAt != null) {
        if (value <= lowErrorAt) {
          col = "#ea0808";
          setColor(col);
        } else if (value > lowErrorAt && value <= lowWarningAt) {
          col = "#eab308";
          setColor(col);
        } else if (value > lowWarningAt) {
          col = "#2563eb";
          setColor(col);
        } else {
          col = "#CCCCCC";
          setColor(col);
        }
      }
      else if (highWarningAt != null && highErrorAt != null) {
        if (value >= highErrorAt) {
          col = "#ea0808";
          setColor(col);
        } else if (value >= highWarningAt && value < highErrorAt) {
          col = "#eab308";
          setColor(col);
        } else if (value < highWarningAt) {
          col = "#2563eb";
          setColor(col);
        } else {
          col = "#CCCCCC";
          setColor(col);
        }
      } else {
        col = "#2563eb";
        setColor(col);
      }

      if (maxValue !== minValue) {
        let val = ((value - minValue) / (maxValue - minValue)) * 100;
        setNormalizedValue(val);
      }
    }
  }, [value]);



  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        normalizedValue={normalizedValue}
        value={value}
        height={height}
        color={color}
        valueUnit={valueUnit}
      />
    </Box>
  );
}

LinearWithValueLabel.propTypes = {
  value: PropTypes.number.isRequired,
  height: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  redRange: PropTypes.arrayOf(PropTypes.number),
  yellowRange: PropTypes.arrayOf(PropTypes.number),
  greenRange: PropTypes.arrayOf(PropTypes.number),
  valueUnit: PropTypes.string,
};

LinearWithValueLabel.defaultProps = {
  valueUnit: "%",
};
