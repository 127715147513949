// ServerSideTable.jsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  Box
} from '@mui/material';
import EnhancedTableHead from './EnhancedTableHead';// Ensure correct path
import PropTypes from 'prop-types';

const ServerSideTableWithSearch = ({
  rows = [],
  totalRows,
  columns,
  defaultSortField = 'inventory_master_name',
  defaultSortOrder = 'asc',
  rowsPerPageOptions = [5, 10, 25],
  handleChangePage, // Function to fetch data from server
}) => {
  const [order, setOrder] = useState(defaultSortOrder);
  const [orderBy, setOrderBy] = useState(defaultSortField);
  const [page, setPage] = useState(0); // Zero-based
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchQuery, setSearchQuery] = useState('');

  // Handle sorting
  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setOrder(newOrder);
      setOrderBy(property);
      setPage(0); // Reset to first page on sort
      handleChangePage(0, rowsPerPage, property, newOrder, searchQuery);
    },
    [order, orderBy, handleChangePage, rowsPerPage, searchQuery]
  );

  // Handle page change
  const handleChangePageInternal = useCallback(
    (event, newPage) => {
      setPage(newPage);
      handleChangePage(newPage, rowsPerPage, orderBy, order, searchQuery);
    },
    [handleChangePage, orderBy, order, rowsPerPage, searchQuery]
  );

  // Handle rows per page change
  const handleChangeRowsPerPageInternal = useCallback(
    (event) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
      handleChangePage(0, newRowsPerPage, orderBy, order, searchQuery);
    },
    [handleChangePage, orderBy, order, searchQuery]
  );

  // Handle search
  const handleSearchChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchQuery(value);
      setPage(0);
      handleChangePage(0, rowsPerPage, orderBy, order, value);
    },
    [handleChangePage, orderBy, order, rowsPerPage]
  );

  // Memoize columns with sortability
  const columnsWithSort = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        sortable: column.sortable !== undefined ? column.sortable : true,
      })),
    [columns]
  );

  return (<>
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2, pt:1 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          size='small'
          sx={{width: '300px'}}
        />
      </Box>
    {/* <div style={{width: '100%', display:' flex', justifyContent: 'flex-end'}}>
        <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ margin: 2, marginLeft: 'auto' }}
        />
    </div> */}
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {/* Search Bar */}
        
      <TableContainer sx={{ maxHeight: 440 }}>
        
        <Table stickyHeader aria-label="server side table">
          <EnhancedTableHead
            columns={columnsWithSort}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.map((row) => (
              <TableRow hover key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align ? column.align : 'left'}>
                    {column.renderCell ? column.renderCell(row) : row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePageInternal}
        onRowsPerPageChange={handleChangeRowsPerPageInternal}
      />
    </Paper>
    </>);
};

ServerSideTableWithSearch.propTypes = {
  rows: PropTypes.array.isRequired,
  totalRows: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  defaultSortOrder: PropTypes.oneOf(['asc', 'desc']),
  rowsPerPageOptions: PropTypes.array,
  handleChangePage: PropTypes.func.isRequired,
};

export default ServerSideTableWithSearch;
