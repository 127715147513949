import React, { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Card, Typography, MenuItem } from "@mui/material";
import axios from "axios";
import config from "../../../Config/Config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselV2, getVesselLocationFromNMEA, getVesselEngineDataForVesselLocation, getVesselForCustomerV2 } from "../../../Helpers/ApplicationHelpers";
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../Components/v2/FMSTopBar";
import FMSTheme from "../../FMSTheme";
import FMSSidebar from '../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../Components/v2/FMSTable';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";
import OpenSeaMap from "../../../Components/v2/FMSOpenSeaMap/index";
import { parseNmeaSentence } from "nmea-simple";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import moment from "moment";
import DmsCoordinates from "dms-conversion";
import RotatedMarker from "../../../Components/RotatedMarker";
import vesselImg from "../../../Assets/Images/new_arrow_pointer.png";
import vesselImg2 from "../../../Assets/Images/new_arrow_pointer_green.png";
import Loading from "../../../Components/v2/FMSLoading/index";
import CardContent from '@mui/material/CardContent';
import "./index.css"

export default function FMSMapPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [gpsData, setGPSData] = useState([]);
    const [listNMEAData, setListNMEAData] = useState([]);
    const [engineAndLocationData, setEngineAndLocationData] = useState();
    const [selectedNMEAData, setSelectedNMEAData] = useState();
    const [listVesselData, setListVesselData] = useState();
    const [listLineData, setListLineData] = useState();
    const [markerData, setMarkerData] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

                loadDetailVesselData();
            }
        }
        checkCookies();
    }, []);


    useEffect(() => {
        if (listVesselData) {
            if (listVesselData.length > 0) {
                console.log(listVesselData);
                let listLineData = Array(listVesselData.length);
                for (let i = 0; i < listVesselData.length; i++) {
                    listLineData[i] = [];
                }
                setListLineData(listLineData);
                loadEngineVesselData();
                const interval = setInterval(() => {
                    loadEngineVesselData();
                    // refreshData();
                }, 6000);

                return () => clearInterval(interval);
            } else {
                setLoading(false);
            }
        }
    }, [listVesselData]);

    useEffect(() => {
        if (markerData) {
            if (markerData.listMarker) {
                if (markerData.listMarker.length > 0) {
                    setLoading(false);
                }
            }
        }
    }, [markerData])


    useEffect(() => {
        if (engineAndLocationData) {
            console.log('engineAndLocationData', engineAndLocationData)
            if (engineAndLocationData.length > 0) {
                console.log('engineAndLocationData', engineAndLocationData)
                let listMarker = [];
                let listVessel = listVesselData;
                let idx = 0;
                let listLineData2 = listLineData.slice();

                

                engineAndLocationData.forEach(data => {
                    if (data.latitude && data.longitude) {
                        if(data.mep_throttle){
                            const temp = parseFloat(data.mep_throttle);
                            if(temp < 0){
                                data.mep_throttle = 0;
                            }
                        }
        
                        if(data.mes_throttle){
                            const temp = parseFloat(data.mes_throttle);
                            if(temp < 0){
                                data.mes_throttle = 0;
                            }
                        }
                        const dmsCoords = new DmsCoordinates(parseFloat(data.latitude), parseFloat(data.longitude));
                        const speedKnots = data.speed;
                        const cogOffset = 0;
                        let markerEl =
                            <RotatedMarker key={idx} position={[data.latitude, data.longitude]} timestamp_gps={data.timestamp_gps} icon={data.is_backup ? vesselIcon2 : vesselIcon} rotationAngle={
                                (parseFloat(data.cog) + parseFloat(data.cogOffset)).toFixed(1) > 359.9 ? ((parseFloat(data.cog) + parseFloat(data.cogOffset)) - 359.9).toFixed(1)
                                    : (parseFloat(data.cog) + parseFloat(data.cogOffset)).toFixed(1)
                            } rotationOrigin='center' vesselId = {data.vessel_id} isBackup={data.is_backup}>
                                <Popup>
                                    <div className="popupContainerVesselMap">
                                        <p className="pForPopupVessel">
                                            {
                                                listVesselData && Array.isArray(listVesselData) &&
                                                listVesselData.find(p => p.id === data.vessel_id)?.vessel_name || ""
                                            }

                                            {/* {listVesselData && listVesselData[0].vessel_name}<br /> */}
                                            <hr />
                                        </p>
                                        <p className="pForPopupVesselBody">
                                            <b>POSITION ({data.is_backup ? "BACKUP" : "AIS" })</b><br />
                                            {`${dmsCoords.latitude.dmsArray[0]}°${dmsCoords.latitude.dmsArray[1]}'${dmsCoords.latitude.dmsArray[2].toFixed(4)}" ${dmsCoords.latitude.dmsArray[3]}` }&emsp;
                                            {`${dmsCoords.longitude.dmsArray[0]}°${dmsCoords.longitude.dmsArray[1]}'${dmsCoords.longitude.dmsArray[2].toFixed(4)}" ${dmsCoords.longitude.dmsArray[3]}` }
                                        </p>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p className="pForPopupVesselBody">
                                                <b>SPEED</b><br />
                                                {data.is_backup ? "N/A" : speedKnots} knots<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>MAIN ENGINE</b><br />
                                                PORT<br />
                                                {data.mep_rpm !== null ? (parseFloat(data.mep_rpm)).toFixed(0) + ' RPM' : 'N/A'}<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>THROTTLE</b><br />
                                                PORT<br />
                                                {data.mep_throttle !== null ? ( parseFloat(data.mep_throttle) < 0 ?  0 : parseFloat(data.mep_throttle)).toFixed(1) + '%' : 'N/A'}<br />
                                            </p>
                                            <p className="pForPopupVesselBody" style={{ textAlign: 'right' }}>
                                                <b>COG</b><br />
                                                {

                                                    data.is_backup ? "N/A" : 
                                                    (parseFloat(data.cog) + parseFloat(cogOffset)).toFixed(1) > 359.9 ? ((parseFloat(data.cog) + parseFloat(cogOffset)) - 359.9).toFixed(1)
                                                        : (parseFloat(data.cog) + parseFloat(cogOffset)).toFixed(1)
                                                } °<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>MAIN ENGINE</b><br />
                                                STARBOARD<br />
                                                {data.mes_rpm !== null ? (parseFloat(data.mes_rpm)).toFixed(0) + ' RPM' : 'N/A'}<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>THROTTLE</b><br />
                                                STARBOARD<br />
                                                {data.mes_throttle !== null ? (parseFloat(data.mes_throttle) < 0 ? 0 : parseFloat(data.mes_throttle)).toFixed(1) + '%' : 'N/A'}<br />
                                            </p>
                                        </div>
                                        <hr />
                                        <p className="pForPopupVesselBody">
                                            <b>Time Stamp:</b> {data.timestamp_gps}
                                        </p>
                                    </div>
                                </Popup>
                            </RotatedMarker>;
                        try {
                            listVessel.find(p => p.id === data.vessel_id).latitude = data.latitude;
                            listVessel.find(p => p.id === data.vessel_id).longitude = data.longitude;
                        }
                        catch (exception) {
                            listVessel.find(p => p.id === data.vessel_id).latitude = 0;
                            listVessel.find(p => p.id === data.vessel_id).longitude = 0;
                        }

                        // console.log(markerEl.props);
                        if (listLineData2) {
                            let listLineDataIdx = listVessel.findIndex(p => p.id === data.vessel_id);
                            let listNewLineData = listLineData2[listLineDataIdx].slice();
                            if (listLineData2[listLineDataIdx]) {
                                if (listLineData2[listLineDataIdx].length > 0) {
                                    let listExpiredLine = listLineData2[listLineDataIdx].filter(p => moment.duration(moment(new Date()).diff(moment(p.props.timestamp_gps))).asMinutes() > 10);
                                    if (listExpiredLine) {
                                        listExpiredLine.forEach((line, idx) => {
                                            let toBeRemovedIdx = listNewLineData.findIndex(p => p.props.timestamp_gps === line.props.timestamp_gps);
                                            if (toBeRemovedIdx > -1) {
                                                listNewLineData.splice(toBeRemovedIdx, 1);
                                            }
                                        });
                                    }
                                    listLineData2[listLineDataIdx] = listNewLineData;
                                    listLineData2[listLineDataIdx].push(markerEl);
                                } else {
                                    listLineData2[listLineDataIdx].push(markerEl);
                                }
                            }
                        }
                        // listLine.push(markerEl);
                        // listTimestamp.push(timeStampGPS);
                        listMarker.push(markerEl);
                        idx++;
                    }

                })

                setListLineData(listLineData2);
                let markerObj = {
                    listVessel: listVessel,
                    listMarker: listMarker,
                    listLine: listLineData2,
                }
                setMarkerData(markerObj);
            }
        }
    }, [engineAndLocationData])

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });


    let vesselIcon2 = L.icon({
        iconUrl: vesselImg2,
        iconRetinaUrl: vesselImg2,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const loadDetailVesselData = async () => {
        try {
            let response = await getVesselForCustomerV2(cookies.token, cookies.extCustomerId);
            // console.log('vessel data', response);
            setListVesselData(response);
            // update
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadNMEAData = async () => {
        try {
            let newArr = []
            for (let i = 0; i < engineAndLocationData.length; i++) {
                let result = await getVesselLocationFromNMEA(cookies.token, cookies.extCustomerId, engineAndLocationData[i].vesselId);
                engineAndLocationData[i].nmeaData = result
                newArr.push(engineAndLocationData[i]);
            }
            setListNMEAData(newArr);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadEngineVesselData = async () => {
        try {
            let result = await getVesselEngineDataForVesselLocation(cookies.token, cookies.extCustomerId);
            console.log(result);
            if (result) {
                let newArr = [];
                for (let i = 0; i < result.length; i++) {
                    const vesselData = {
                        latitude: result[i].latitude,
                        longitude: result[i].longitude,
                        cog: result[i].cog,
                        speed: result[i].speed,
                        speedKnots: result[i].speed,
                        mep_rpm: result[i].mep_rpm,
                        mes_rpm: result[i].mes_rpm,
                        mep_throttle: result[i].mep_throttle,
                        mes_throttle: result[i].mes_throttle,
                        timestamp_gps: moment.utc(result[i].read_date_parse).local().format("YYYY-MM-DD HH:mm"),
                        cogOffset: '0',
                        vessel_id: result[i].vessel_id,
                        is_backup: result[i].is_backup
                    };
                    newArr.push(vesselData);
                }
                // console.log(newArr);
                setEngineAndLocationData(newArr);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const handleOnToggleBackupGPS = (isChecked) => {
        console.log("use backup GPS:", isChecked);
    };

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY'>
                        <OpenSeaMap markerChild={markerData} isTracking={false} onToggleBackupGPS={handleOnToggleBackupGPS}
                        />
                    </div>
                </div>
                <div className='loadingContainerVesselMapFMS'>
                    <Loading
                        loading={loading}
                        loadingText={`LOADING`}
                    />
                </div>
            </div>
        </>
    );

}