import './index.css'
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useCookies } from "react-cookie";
import "../../../AppV2.css";
import { Card, CardContent, CardHeader, Divider, Grid2, Icon, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { Box, TextField, Paper } from "@mui/material";

import { Container } from 'react-bootstrap';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getEquipmentCategoryForCustomerV2 } from '../../../../Helpers/ApplicationHelpers';


const HoverButtonWithArrow = ({text, level, row, renderChild, childData, onClickChild, onClickArrow}) => {
    return (
        <Box sx={{
            width: '100%', 
            display: 'flex', 
            backgroundColor: "primary.white",
            '&:hover .arrow': { 
                opacity: 1, 
            }}}>
            <Button
                sx={{
                    width: '200px',
                    height: '40px',
                    display: 'flex',
                    flex: 3,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 10px',
                    textTransform: 'none', 
                    position: 'relative',
                    backgroundColor: "inherit",
                    color: "#1864c4", 
                    "&:hover": {
                        backgroundColor: "#e0e0e0", 
                    },
                    "&:focus": {
                        backgroundColor: "#1864c4", 
                        color: "white",
                    },
                }}
                onClick={() => {
                    onClickChild(level, row, childData);
                }}
                >
                {text && <span>{text}</span>}
                {renderChild && renderChild(level, row, childData)}
            </Button>
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    onClickArrow(level, row, childData);
                }}
                sx={{ display: 'flex', flex: 1, alignItems: 'center' }}
            >
                <ArrowForwardIcon
                sx={{
                    opacity: 0,  
                    transition: 'opacity 0.3s ease',
                }}
                className="arrow"
                />
            </Button>
        </Box>
    );
};

const HoverButton = ({text, level, row, renderChild, childData, onClickChild}) => {
    return (
        <Box sx={{
            width: '100%', 
            display: 'flex', 
            backgroundColor: "primary.white",
            '&:hover .arrow': { 
                opacity: 1, 
            }}}>
            <Button
                sx={{
                    width: '200px',
                    height: '40px',
                    display: 'flex',
                    flex: 3,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 10px',
                    textTransform: 'none', 
                    position: 'relative',
                    backgroundColor: "inherit",
                    color: "#1864c4", 
                    "&:hover": {
                        backgroundColor: "#e0e0e0", 
                    },
                    "&:focus": {
                        backgroundColor: "#1864c4", 
                        color: "white",
                    },
                }}
                onClick={() => {
                    onClickChild(level, row, childData);
                }}
                >
                {text && <span>{text}</span>}
                {renderChild && renderChild(level, row, childData)}
            </Button>
        </Box>
    );
};

const RenderChildren = React.memo(({ children, level, renderChild, onClickChild, onClickArrow, isFollowPreviousRow = false, startRow = 0 }) => {
    let row = 0;
    let fillerElements = [];
    if(isFollowPreviousRow){
        fillerElements = Array.from({ length: startRow }, (_, index) => {
            row = index;
            return <Box key={index} sx={{ width: '200px', height: '40px' }} />
        });
    }

    if(row > 0){
        row++;
    }

    return (
        <Stack spacing={2}>
            {isFollowPreviousRow && fillerElements}
            <Paper>
                <Stack spacing={2}>
                    <Button sx={{backgroundColor: "#1864c4", color: "white"}}>Add Category</Button>
                    {children.map((child) => {
                        return <HoverButton text={child.text || null} childData={child} level={level} row={row++} renderChild={renderChild} onClickChild={onClickChild} onClickArrow={onClickArrow}/>
                    })}
                </Stack>
            </Paper>
        </Stack>
    );
});


const SideTreeView = React.memo(({data, renderChild, onClickChild}) => {
    return <>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            {data.length > 0 && data.map((children, index) => {
            return <RenderChildren 
                children={children}
                level={index} 
                renderChild={renderChild}
                onClickChild={onClickChild}
                isFollowPreviousRow={false}
            />
           })}
        </Stack>
    </>
});

export default function AdminEquipmentCategoryPage({ }) {
    const [cookies, removeCookie] = useCookies(["token"]);

    const [selectedCategory, setSelectedCategory] = useState();

    const [mappedCategories, setMappedCategories] = useState({});

    const [categoriesToRender, setCategoriesToRender] = useState([]);


    useEffect(() => {
        loadEquipmentCategoryParents();
    }, []);

    useEffect(() => {
        console.log("Selected Category");
        console.log(selectedCategory);
    }, [selectedCategory]);
    
    useEffect(() => {
        console.log("CATEGORIES TO RENDER VALUES" , categoriesToRender);
    }, [categoriesToRender])

    useEffect(() => {
        console.log("MAPPED CATEGORIES", mappedCategories);
    }, [mappedCategories])

    const addCategoryToRenderByIndex = (startColumn, startRow, categories) => {
        const updatedCategories = categoriesToRender;
        

        if (updatedCategories.length >= startColumn + 1) {
            updatedCategories.splice(startColumn, updatedCategories.length - startColumn);
        }

        let rowId = startRow;
        categories.forEach((category, index) => {
            category["rowId"] = rowId++;
            category["columnId"] = startColumn;
            // caetegory["childrenIds"] = [];
        });

        updatedCategories.push(categories);
        console.log("UPDATED CATEGORIES", updatedCategories);
        setCategoriesToRender([...updatedCategories]);
    }


    const loadEquipmentCategoryParents = async () => {
        try {
            const results = await getEquipmentCategoryForCustomerV2(cookies.token, null);
            addCategoryToRenderByIndex(0, 0, results);
            storeToMappedCategories(0, 0, results);
        }catch(exception) {
            console.error(exception);
        }
    }

    const storeToMappedCategories = (columnId, rowId, results, parentId = null) => {
        const newMapped = {};
        if(parentId !== null){
            const childrenIds = results.map((r) => r.id) ;
            const parentData = mappedCategories[parentId];
            parentData.childrenIds = childrenIds;
            newMapped[parentId] = parentData;
        }
        let newRowId = rowId;
        results.forEach((r) => {
            const temp = r;
            temp["rowId"] = newRowId++;
            temp["columnId"] = columnId;
            temp["childrenIds"] = [];
            newMapped[r.id] = temp;
        });

        setMappedCategories({...mappedCategories, ...newMapped});
    }

    const loadEquipmentCategoryChildrens = async (columnId, rowId, parentId) => {
        try {
            const parent = mappedCategories[parentId];
            const newColumnId = columnId+1;
            let results = [];
            if(parent.childrenIds.length > 0){
                results = getChildrensData(parent.childrenIds)
            }else{
                results = await getEquipmentCategoryForCustomerV2(cookies.token, parentId);
                storeToMappedCategories(newColumnId, rowId, results, parentId);
            }
            addCategoryToRenderByIndex(newColumnId, rowId, results);
        }catch(exception) {
            console.error(exception);
        }
    }

    const getChildrensData = (childrenIds) => {
        const childrensData = childrenIds.map((id) => {
            return mappedCategories[id];
        });

        return childrensData;
    }



    const expandCategory = (level, row, categoryId) => {
        loadEquipmentCategoryChildrens(level, row, categoryId);
    }

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY overflow-auto'>
                        <Container fluid>
                            <Grid2 container spacing={2}>
                                <Grid2 size={10} sx={{ height: '300px', border: '1px solid grey' }} variant="outlined" className="overflow-auto">
                                    <Container fluid>
                                        {categoriesToRender.length > 0 && 
                                        <SideTreeView 
                                            data={categoriesToRender} 
                                            renderChild={(level, row, childData) => { return <>{childData.equipment_category_name}</>}}  
                                            onClickChild={(level, row, childData) => {
                                                setSelectedCategory(childData);
                                                expandCategory(level, row, childData.id);
                                        }}/> }
                                    </Container>
                                </Grid2>
                            </Grid2>
                        </Container>
                        
                    </div>
                </div>
            </div>
        </>
    );
}