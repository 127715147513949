import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../../../AppV2.css";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

import Loading from "../../../../../Components/Loading";

import { vesselTrackingGenerateReportPDF } from "../../../../../Helpers/ApplicationHelpers";

import DynamicLineChartDoubleAxis from "../../../../../Components/v2/FMSChart";

// import noImg from "../../../../../Assets/Images/FMS/vessel_fms_icon.png";
export default function PrintReportTracking({ state }) {

    const trackingReport = state.trackingReport;
    const enginePerformance = trackingReport.enginePerformance;
    const fuelConsumption = trackingReport.fuelConsumption;

    const lastLatitude = state.latitude;
    const lastLongitude = state.longitude;

    let counter = 0;
    const counterMax = 2;
    
    const a4Style = {
        width: '21cm',
        height: '29.7cm',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        overflow: 'hidden',
    };

    const mainContainer = {
        width: '100%',
        border: '1px black solid',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px'
    }

    const titleStyle = {
        fontSize: 'medium',
        fontWeight: 'bold',
        margin: '0',
        fontFamily: 'Arial'
    }

    const paragraphStyle = {
        fontSize: 'smaller',
        fontWeight: 'lighter',
        margin: '0',
        fontFamily: 'Arial'
    }

    const tableContainer = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
    }

    const tableBorder = {
        width: '100%',
        border: '1px black solid',
        tableLayout: 'fixed'
    }

    const tableContent = {
        fontSize: 'smaller',
        fontWeight: 'lighter',
        fontFamily: 'Arial',
        padding: '2px',
        border: '1px black solid',
        color: 'black'
    }

    const enginePerformanceContainer = {
        width: '100%',
        margin: '10px 0px'
    }

    const eachlineChartContainerFuelConsumption = {
        border: '1px rgba(3, 30, 103, 1) solid',
        width: '100%',
        maxHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 0px',
        margin: '10px 5px',
        borderRadius: '5px',
    }

    const pageBreak = {
        pageBreakAfter: 'always'
    };

    useEffect(() => {
        console.log("PRINTING STATES");
        console.log(state);
        console.log("LOADING HAHAHAHA");
    }, []);

    const renderGraphEnginePerformance = (data1, data2, firstLabel, secondLabel, min1, max1, min2, max2, unit1, unit2) => {
        const reading1 = data1.map(d => d.reading_value);
        const dates1 = data1.map(d => d.interval_reading);

        const reading2 = data2.map(d => d.reading_value);
        const dates2 = data2.map(d => d.interval_reading);

        const dates = (dates1.length > dates2.length)? dates1 : dates2;

        const label1 = `${firstLabel}`;
        const label2 = `${secondLabel}`;


        return (<DynamicLineChartDoubleAxis
            chartData={
                {
                    firstDataset: reading1 || [],
                    secondDataset: reading2 || []
                }
            }

            xAxisLabels={dates}

            chartLimits={{
                y1: { min: min1|| 0, max: max1 || 0, division: 5 },
                y2: { min: min2 || 0, max: max2 || 0, division: 5 },
            }}

            labels= {{
                firstLabel: label1,
                secondLabel: label2
            }}
        />)
    }


    const renderGraphFuelConsumption = (data1, data2, firstLabel, secondLabel, isIncremental = false) => {
            const reading1 = data1.map(d => d.reading_value);
            const dates1 = data1.map(d => d.interval_reading);

            const reading2 = data2.map(d => d.reading_value);
            const dates2 = data2.map(d => d.interval_reading);
            let data1Value =  reading1 || [];
            let data2Value =  reading2 || [];
            let min1 = Math.min(...data1Value);
            let min2 = Math.min(...data2Value);
            let max1 = Math.max(...data1Value);
            let max2 = Math.max(...data2Value);
    
            
            if(isIncremental){
                let offsetValues1 = data1Value.map(value => value - min1) || [];
                let offsetValues2 = data2Value.map(value => value - min2) || [];
    
                let offsetMax1 = max1 - min1;
                let offsetMax2 = max2 - min2;
    
                min1 = 0;
                min2 = 0;
                max1 = offsetMax1;
                max2 = offsetMax2;
    
                data1Value = offsetValues1;
                data2Value = offsetValues2;
            }
    
            let max = Math.max(max1, max2);
            let min = Math.min(min1, min2);
    
            const dates = (dates1.length > dates2.length)? dates1 : dates2;
    
    
            const label1 = `${firstLabel}`;
            const label2 = `${secondLabel}`;
    
    
    
            return (<DynamicLineChartDoubleAxis
                chartData={
                    {
                        firstDataset: data1Value,
                        secondDataset: data2Value
                    }
                }
    
                xAxisLabels={dates}
    
                chartLimits={{
                    y1: { min: min || 0, max: max || 0, division: 5 },
                    y2: { min: min || 0, max: max || 0, division: 5 },
                }}
    
                labels= {{
                    firstLabel: label1,
                    secondLabel: label2
                }}
            />)
        }

    function splitCamelCase(input) {
        return input.replace(/([a-z])([A-Z])/g, "$1 $2");
    }
    
    return (
        <>
            <div style={mainContainer}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: '50%' }} src={state.companyLogo.logo_image} />
                </div>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={{ fontSize: 'large', fontWeight: 'bold' }}>{state.vesselData.vessel_name && state.vesselData.vessel_name}</p>
                    <p style={{ fontSize: 'medium', fontWeight: 'lighter' }}>{state.vesselData.customer_name}</p>
                </div>
            </div>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>VESSEL OPERATION SUMMARY</h2>
            </div>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Start Date'}</p>
                    <p style={paragraphStyle}>{moment(state.startTime).local().format("YYYY-MM-DD HH:mm")}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'End Date'}</p>
                    <p style={paragraphStyle}>{moment(state.endTime).local().format("YYYY-MM-DD HH:mm")}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>

                </div>
            </div>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Total Distance'}</p>
                    <p style={paragraphStyle}>{state.totalDistance ? `${(parseFloat(state.totalDistance)).toFixed(1)} NM` : `N/A`}</p>
                </div>
                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Average Speed'}</p>
                    <p style={paragraphStyle}>{averageSpeed ? `${parseFloat(averageSpeed).toFixed(1)} KNOT` : `N/A`}</p>
                </div> */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Duration'}</p>
                    <p style={paragraphStyle}>{state.totalDuration ? state.totalDuration : '0'}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>

                </div>
            </div>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Latitude'}</p>
                    <p style={paragraphStyle}>{lastLatitude || 'N/A'}</p>
                </div>
                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Average Speed'}</p>
                    <p style={paragraphStyle}>{averageSpeed ? `${parseFloat(averageSpeed).toFixed(1)} KNOT` : `N/A`}</p>
                </div> */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <p style={titleStyle}>{'Longitude'}</p>
                    <p style={paragraphStyle}>{lastLongitude || 'N/A'}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>

                </div>
            </div>

            <div style={enginePerformanceContainer}>
                <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>ENGINE PERFORMANCES</h2>
                </div>
                {Object.keys(enginePerformance).map(engineName => {
                    const engine = enginePerformance[engineName];
                    const port = engine.Port;
                    const starboard = engine.Starboard;
                    return (<>
                        <div style={tableContainer}>
                            <table style={tableBorder}>
                                <thead>
                                    <tr>
                                        <th style={tableContent}><p style={titleStyle}>{`${splitCamelCase(engineName)}`}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Port Average'}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Starboard Average'}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Port Low'}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Port High'}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Starboard Low'}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Starboard High'}</p></th>
                                    </tr>
                                </thead>
                                {Object.keys(port).map(enginePart=> {
                                    const engineDataPort = port[enginePart];
                                    const engineDataStarboard = starboard[enginePart];
                                    const unitPort = engineDataPort.unit;
                                    const unitStarboard = engineDataStarboard.unit;
                                    let portLow = engineDataPort.summary.lowestReading;
                                    let portHigh = engineDataPort.summary.highestReading;
                                    let starboardLow = engineDataStarboard.summary.lowestReading;
                                    let starboardHigh = engineDataStarboard.summary.highestReading;

                                    let averagePort = engineDataPort.summary.total / engineDataPort.summary.count || 1;
                                    let averageStarboard = engineDataStarboard.summary.total / engineDataStarboard.summary.count || 1;

                                    if(unitPort === '%'){
                                        averagePort = parseFloat(averagePort) < 0 ? 0 : averagePort;
                                        portLow = parseFloat(portLow) < 0 ? 0 : portLow;
                                        portHigh = parseFloat(portHigh) < 0 ? 0 : portHigh;
                                    }

                                    if(unitStarboard === '%'){
                                        averageStarboard = parseFloat(averageStarboard) < 0 ? 0 : averageStarboard;
                                        starboardLow = parseFloat(starboardLow) < 0 ? 0 : starboardLow;
                                        starboardHigh = parseFloat(starboardHigh) < 0 ? 0 : starboardHigh;
                                    }

                                    return <tbody>
                                        <tr>
                                            <td style={tableContent}><p style={paragraphStyle}>{enginePart}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{`${parseFloat(averagePort).toFixed(2)} ${unitPort}`}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{`${parseFloat(averageStarboard).toFixed(2)} ${unitStarboard}`}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{parseFloat(portLow).toFixed(2)} {unitPort}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{parseFloat(portHigh).toFixed(2)} {unitPort}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{parseFloat(starboardLow).toFixed(2)} {unitStarboard}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{parseFloat(starboardHigh).toFixed(2)} {unitStarboard}</p></td>
                                        </tr>
                                    </tbody>
                                })}
                            </table>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>
                    </>)
                })}
            </div>

            <div style={enginePerformanceContainer}>
                <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>FUEL CONSUMPTION</h2>
                </div>
                {Object.keys(fuelConsumption).map(engineName => {
                    const engine = fuelConsumption[engineName];
                    const port = engine.Port;
                    const starboard = engine.Starboard;
                    return (<>
                        <div style={tableContainer}>
                            <table style={tableBorder}>
                                <thead>
                                    <tr>
                                        <th style={tableContent}><p style={titleStyle}>{`${splitCamelCase(engineName)}`}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Port'}</p></th>
                                        <th style={tableContent}><p style={titleStyle}>{'Starboard'}</p></th>
                                    </tr>
                                </thead>
                                {Object.keys(port).map(enginePart=> {
                                    const engineDataPort = port[enginePart];
                                    const engineDataStarboard = starboard[enginePart];
                                    const unitPort = engineDataPort.unit;
                                    const unitStarboard = engineDataStarboard.unit;
                                    const isIncremental = engineDataPort.summary.isIncremental;

                                    const minPort = engineDataPort.summary.lowestReading;
                                    const maxPort = engineDataPort.summary.highestReading;
                                    const minStarboard = engineDataStarboard.summary.lowestReading;
                                    const maxStarboard = engineDataStarboard.summary.highestReading;
                                    let averagePort = 0;
                                    if(engineDataPort.summary.count){
                                        averagePort = engineDataPort.summary.total / engineDataPort.summary.count;
                                    }
                                    let averageStarboard = engineDataStarboard.summary.total / engineDataStarboard.summary.count || 1;
                                    let textAfter = "Average";
                                    let valueToShowPort = averagePort;
                                    let valueToShowStarboard = averageStarboard;
                                    
                                    if(unitPort === '%'){
                                        valueToShowPort = parseFloat(valueToShowPort) < 0 ? 0 : averagePort;
                                    }

                                    if(unitStarboard === '%'){
                                        valueToShowStarboard = parseFloat(valueToShowStarboard) < 0 ? 0 : averageStarboard;
                                    }

                                    if(isIncremental){
                                        textAfter = "";
                                        valueToShowPort = maxPort - minPort;
                                        valueToShowStarboard = maxStarboard - minStarboard;
                                    }

                                    return <tbody>
                                        <tr>
                                            <td style={tableContent}><p style={paragraphStyle}>{enginePart} {textAfter}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{`${parseFloat(valueToShowPort).toFixed(2)} ${unitPort}`}</p></td>
                                            <td style={tableContent}><p style={paragraphStyle}>{`${parseFloat(valueToShowStarboard).toFixed(2)} ${unitStarboard}`}</p></td>
                                        </tr>
                                    </tbody>
                                })}
                            </table>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>
                    </>)
                })}
            </div>

            <div style={pageBreak}></div>
            
            <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>ENGINE PERFORMANCES</h2>
            </div>
            {Object.keys(enginePerformance).map(engineName => {
                    const engine = enginePerformance[engineName];
                    const port = engine.Port;
                    const starboard = engine.Starboard;
                    return Object.keys(port).map(enginePart=> {
                        const engineDataPort = port[enginePart];
                        const engineDataStarboard = starboard[enginePart];
                        const unitPort = engineDataPort.unit;
                        const unitStarboard = engineDataStarboard.unit;
                        const minPort = engineDataPort.summary.min;
                        const maxPort = engineDataPort.summary.max;
                        const minStarboard = engineDataPort.summary.min;
                        const maxStarboard = engineDataPort.summary.max;
                        let elementPageBreak = "";
                        counter++;
                        if(counter >= 2){
                            counter = 0;
                            elementPageBreak = <div style={pageBreak}></div>
                        }
                        return <>
                            <div className="eachlineChartContainerFuelConsumption" style={eachlineChartContainerFuelConsumption}>
                                <h4 className="txtFortankNameFuelConsumption">{splitCamelCase(engineName)} {enginePart}</h4>
                                {renderGraphEnginePerformance(
                                    engineDataPort.data,
                                    engineDataStarboard.data,
                                    `${enginePart} Port (${unitPort})`, `${enginePart} Starboard (${unitStarboard})`,
                                    minPort, maxPort,
                                    minStarboard, maxStarboard
                                )}
                            </div>

                            <div style={{ width: '100%', height: '20px' }}></div>
                            {elementPageBreak}
                        </>
                    });
                })
            }
            {(counter > 0)?  <div style={pageBreak}></div> : ""}
            {counter = 0}

            <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>FUEL CONSUMPTION</h2>
            </div>
            {Object.keys(fuelConsumption).map(engineName => {
                    const engine = fuelConsumption[engineName];
                    const port = engine.Port;
                    const starboard = engine.Starboard;
                    return Object.keys(port).map(enginePart=> {
                        const engineDataPort = port[enginePart];
                        const engineDataStarboard = starboard[enginePart];
                        const unitPort = engineDataPort.unit;
                        const unitStarboard = engineDataStarboard.unit;
                        const isIncremental = engineDataPort.summary.isIncremental;
                        let elementPageBreak = "";
                        counter++;
                        if(counter >= 2){
                            counter = 0;
                            elementPageBreak = <div style={pageBreak}></div>
                        }
                        return <>
                            <div className="eachlineChartContainerFuelConsumption" style={eachlineChartContainerFuelConsumption}>
                                <h4 className="txtFortankNameFuelConsumption">{splitCamelCase(engineName)} {enginePart}</h4>
                                {renderGraphFuelConsumption(
                                    engineDataPort.data,
                                    engineDataStarboard.data,
                                    `${enginePart} Port (${unitPort})`, `${enginePart} Starboard (${unitStarboard})`, 
                                    isIncremental
                                )}
                            </div>

                            <div style={{ width: '100%', height: '20px' }}></div>
                            {elementPageBreak}
                        </>
                    });
                })
            }
            

            {/* {window.print()} */}

            
        </>
    );
}
