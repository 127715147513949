// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vesselEquipmentMainContainer{
    width: 100%;
    /* border: 1px red solid; */
}

.fmsEnhancedTableContainerInventory{
    width: 100%;
    /* border: 1px black solid; */
    padding: 1px 10px
}

.invAddButtonContainer{
    width: 100%;
    /* border: 1px red solid; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/v2/Admin/Pages/AdminInventoryMaster/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B;AACJ;;AAEA;IACI,WAAW;IACX,2BAA2B;IAC3B,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".vesselEquipmentMainContainer{\n    width: 100%;\n    /* border: 1px red solid; */\n}\n\n.fmsEnhancedTableContainerInventory{\n    width: 100%;\n    /* border: 1px black solid; */\n    padding: 1px 10px\n}\n\n.invAddButtonContainer{\n    width: 100%;\n    /* border: 1px red solid; */\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
